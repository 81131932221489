import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
//
import Template from "containers/template";
//
export default () => (
  <Template>
    <Wrap>
      <Title>Page not found</Title>
      <Subtitle>You look lost</Subtitle>
      <Link to="/" title="Go Home">
        Go Home
      </Link>
    </Wrap>
  </Template>
);
const Wrap = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;
  text-align: center;

  a {
    border: 1px solid currentColor;
    display: inline-block;
    line-height: 1;
    padding: 1rem;
    text-transform: uppercase;
  }
`;
const Title = styled.h1`
  font-size: 1rem;
  margin: 0;
  text-transform: uppercase;
`;
const Subtitle = styled.h2`
  font-size: 2rem;
  font-weight: 100;
  letter-spacing: 4px;
  margin: 2rem 0;
  text-transform: uppercase;

  @media (min-width: 480px) {
    font-size: 3rem;
  }
`;
